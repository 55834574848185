import React, { Component } from "react";
import TopBar from "../components/TopBar";
import { firebaseService } from "../services/firebaseService";
import { auth } from "../services/firebaseConfig";
import "../styles/GroceryPage.css";

class GroceryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ingredients: [],
      checkedItems: {},
      newItemName: "",
      sortOption: "byMeal", // Default sorting option
    };
  }

  async componentDidMount() {
    try {
      const userId = auth.currentUser.uid;

      // Fetch the grocery list from Firebase
      const groceryList = await firebaseService.getGroceryList(userId);

      if (groceryList.length > 0) {
        this.setState({ ingredients: groceryList });
        console.log("These are ingredients for GroceryPage", groceryList);
      }

      // Load checked items from local storage
      const savedCheckedItems =
        JSON.parse(localStorage.getItem("checkedItems")) || {};
      this.setState({ checkedItems: savedCheckedItems });
    } catch (error) {
      console.error("Error fetching grocery list:", error);
    }
  }

  handleCheckboxChange = (ingredientId) => {
    this.setState((prevState) => {
      const updatedCheckedItems = {
        ...prevState.checkedItems,
        [ingredientId]: !prevState.checkedItems[ingredientId],
      };

      // Save checked items to local storage
      localStorage.setItem("checkedItems", JSON.stringify(updatedCheckedItems));

      return { checkedItems: updatedCheckedItems };
    });
  };

  handleAddItem = async () => {
    const { newItemName, ingredients } = this.state;

    if (newItemName.trim()) {
      const userId = auth.currentUser.uid;

      const newItem = {
        display: newItemName.trim(),
        details: {
          quantity: 1, // Default quantity
          unit: null,
          name: newItemName.trim(),
          meals: ["Manually Added"], // Indicate it was manually added
        },
      };

      // Add the new item to the database
      try {
        await firebaseService.addItemToGroceryList(userId, newItem);

        // Add it to the state
        this.setState((prevState) => ({
          ingredients: [
            ...prevState.ingredients,
            { id: Date.now().toString(), ...newItem }, // Temporarily add with a unique ID
          ],
          newItemName: "",
        }));
      } catch (error) {
        console.error("Error adding new item:", error);
      }
    }
  };

  handleNewItemChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleResetGroceryList = async () => {
    try {
      const userId = auth.currentUser.uid;

      // Reset the entire grocery list for the user
      await firebaseService.resetGroceryList(userId);

      // Clear all ingredients and checked items in the state
      this.setState({ ingredients: [], checkedItems: {} });

      // Clear local storage
      localStorage.removeItem("checkedItems");
    } catch (error) {
      console.error("Error resetting grocery list:", error);
    }
  };

  handleSortChange = (e) => {
    this.setState({ sortOption: e.target.value });
  };

  groupByMeal = () => {
    const { ingredients } = this.state;
    const grouped = {};

    ingredients.forEach((ingredient) => {
      const meal = ingredient.details.meals[0] || "Uncategorized"; // Default meal name
      if (!grouped[meal]) grouped[meal] = [];
      grouped[meal].push(ingredient);
    });

    return grouped;
  };

  renderListByMeal = () => {
    const { checkedItems } = this.state;
    const groupedIngredients = this.groupByMeal();

    return Object.keys(groupedIngredients).map((meal) => (
      <div key={meal} className="meal-group">
        <h3 className="meal-heading">{meal}</h3>
        <ul className="grocery-list">
          {groupedIngredients[meal].map((ingredient) => (
            <li key={ingredient.id} className="grocery-item">
              <label className="grocery-item-label">
                <input
                  type="checkbox"
                  checked={!!checkedItems[ingredient.id]}
                  onChange={() => this.handleCheckboxChange(ingredient.id)}
                  className="styled-checkbox"
                />
                <span
                  className={checkedItems[ingredient.id] ? "purchased" : ""}
                >
                  {ingredient.display}
                </span>
              </label>
            </li>
          ))}
        </ul>
      </div>
    ));
  };

  render() {
    const { newItemName, sortOption } = this.state;

    return (
      <div className="grocery-page">
        <TopBar title="Grocery List" />

        {/* Sorting dropdown */}
        <div className="sort-container">
          <label htmlFor="sortOptions">Sort by:</label>
          <select
            id="sortOptions"
            value={sortOption}
            onChange={this.handleSortChange}
            className="sort-dropdown"
          >
            <option value="byMeal">By Meal</option>
            <option value="byName">By Name</option>
          </select>
        </div>

        <div className="grocery-list-container">
          {sortOption === "byMeal" ? (
            this.renderListByMeal()
          ) : (
            <ul className="grocery-list">
              {this.state.ingredients
                .sort((a, b) => a.details.name.localeCompare(b.details.name))
                .map((ingredient) => (
                  <li key={ingredient.id} className="grocery-item">
                    <label className="grocery-item-label">
                      <input
                        type="checkbox"
                        checked={!!this.state.checkedItems[ingredient.id]}
                        onChange={() =>
                          this.handleCheckboxChange(ingredient.id)
                        }
                        className="styled-checkbox"
                      />
                      <span
                        className={
                          this.state.checkedItems[ingredient.id]
                            ? "purchased"
                            : ""
                        }
                      >
                        {ingredient.display}
                      </span>
                    </label>
                  </li>
                ))}
            </ul>
          )}

          {/* Add item input */}
          <div className="add-item-container">
            <input
              type="text"
              name="newItemName"
              value={newItemName}
              onChange={this.handleNewItemChange}
              placeholder="Item name"
              className="add-item-input"
            />
            <button onClick={this.handleAddItem} className="add-item-button">
              Add Item
            </button>
          </div>

          {/* Reset button */}
          <div className="reset-button-container">
            <button
              onClick={this.handleResetGroceryList}
              className="reset-button"
            >
              Reset Grocery List
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default GroceryPage;
