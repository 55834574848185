import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Card,
  CardMedia,
  CardContent,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import "../styles/MatchCard.css"; // Import the CSS file

const MatchCardAlt = ({
  match,
  onUnlike,
  showUnlikeButton,
  onAddToMealPlan,
  onCardClick,
}) => {
  const navigate = useNavigate();
  const { mealId, name, imageUrl } = match;

  const handleCardClick = () => {
    if (mealId) {
      if (onCardClick) onCardClick(); // Save scroll position
      navigate(`/meal/${mealId}`, {
        state: { scrollPosition: window.scrollY }, // Pass scroll position
      });
    }
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "260px",
        borderRadius: 5,
        bgcolor: "#FAF3DD",
        boxShadow: "0 0 0 rgba(0, 0, 0, 0)",
        position: "relative",
        overflow: "visible",
        pointerEvents: "auto", // Ensure child elements handle events separately
      }}
      onClick={handleCardClick}
    >
      {/* Add button */}
      <Box
        sx={{
          position: "absolute",
          top: 0, // Adjust the position to make it overflow slightly
          left: -5, // Adjust as needed for visual effect
          bgcolor: "rgba(63, 167, 214, 1)",
          borderRadius: "50%",
          padding: 0.5,
          overflow: "visible", // Allow the icon to overflow
        }}
      >
        <IconButton
          onClick={(e) => {
            e.preventDefault(); // Prevent default behavior
            e.stopPropagation(); // Stop React synthetic event propagation
            e.nativeEvent.stopImmediatePropagation(); // Stop native event propagation
            onAddToMealPlan(match);
          }}
          size="small"
          sx={{ padding: 0, bgcolor: "rgba(0, 0, 0, 0)" }}
        >
          <AddIcon />
        </IconButton>
      </Box>
      {/* Text content above the image */}
      <CardContent
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: 0,
        }}
      >
        <Typography
          variant="h6"
          component="div"
          gutterBottom
          sx={{
            paddingTop: 2,
            fontSize: 15,
            fontWeight: "bold",
            color: "#1b1919",
            fontFamily: "'Croissant One', cursive",
            lineHeight: 1.1,
          }}
        >
          {name
            ? name.length > 100
              ? `${name.slice(0, 100)}...`
              : name
            : "No title available"}
        </Typography>
      </CardContent>

      {/* Image positioned at the bottom left */}
      <Box
        sx={{
          position: "relative",
        }}
      >
        {imageUrl && (
          <CardMedia
            component="img"
            height="140"
            image={imageUrl}
            alt={name}
            sx={{
              position: "relative",
              left: 0,
              overflow: "visible", // Ensure overflow is visible for the image too
              objectFit: "cover",
              width: "100%",
              transform: "translateX(-35px)",
            }}
          />
        )}
      </Box>

      {/* Unlike button */}
      {showUnlikeButton && (
        <Box
          sx={{
            position: "absolute",
            bottom: 10,
            right: 10,
            bgcolor: "rgba(255, 87, 87, 1)",
            borderRadius: "50%",
            padding: 0.5,
          }}
        >
          <IconButton
            onClick={(e) => {
              e.preventDefault(); // Prevent default behavior
              e.stopPropagation(); // Stop React synthetic event propagation
              e.nativeEvent.stopImmediatePropagation(); // Stop native event propagation
              onUnlike(mealId); // Call the onUnlike handler
            }}
            size="small"
            sx={{ padding: 0 }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
    </Card>
  );
};

export default MatchCardAlt;
