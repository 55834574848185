import React from "react";
import "../styles/SearchBar.css";

const SearchBar = ({ placeholder, onSearchChange }) => {
  return (
    <div className="search-bar">
      <input
        type="text"
        className="search-input"
        placeholder={placeholder || "Search..."}
        onChange={(e) => onSearchChange(e.target.value)}
      />
    </div>
  );
};

export default SearchBar;
