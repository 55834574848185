import React, { Component } from "react";
import { Link } from "react-router-dom";
import TopBar from "../components/TopBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faUtensils,
  faDrumstickBite,
  faTrash,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { firebaseService } from "../services/firebaseService";
import { auth } from "../services/firebaseConfig";
import "../styles/MealPlanPage.css";
import AddDirectMealModal from "../components/AddDirectMealModal";

// Static data for the first letters of the days of the week and meal icons
const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const mealIcons = {
  Breakfast: faCoffee,
  Lunch: faUtensils,
  Dinner: faDrumstickBite,
};

class MealPlanPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mealPlan: [], // Initialize with an empty meal plan
      showAddDirectMealModal: false,
      selectedDay: "",
    };
  }

  async componentDidMount() {
    try {
      const userId = auth.currentUser.uid;
      const mealPlanData = await firebaseService.getMealPlan(userId);

      // Map the meal plan data and include nutrition details
      const mealPlanWithDetails = Object.keys(mealPlanData).map((key) => ({
        id: key, // Firebase-generated key for the meal plan entry
        mealId: mealPlanData[key].mealId,
        day: mealPlanData[key].day,
        time: mealPlanData[key].time,
        name: mealPlanData[key].name || "Unknown Meal", // Use meal name if available
        nutrition: {
          calories: mealPlanData[key].nutrition?.calories || "N/A",
          fibres: mealPlanData[key].nutrition?.fibres || "N/A",
          glucides: mealPlanData[key].nutrition?.glucides || "N/A",
          matieresGrasses:
            mealPlanData[key].nutrition?.matieresGrasses || "N/A",
          proteines: mealPlanData[key].nutrition?.proteines || "N/A",
        },
      }));

      this.setState({ mealPlan: mealPlanWithDetails });
    } catch (error) {
      console.error("Error fetching meal plan:", error);
    }
  }

  handleOpenAddDirectMealModal = (day) => {
    this.setState({ showAddDirectMealModal: true, selectedDay: day });
  };

  handleCloseAddDirectMealModal = () => {
    this.setState({ showAddDirectMealModal: false, selectedDay: "" });
  };

  handleAddDirectMeal = async (newMeal) => {
    try {
      const userId = auth.currentUser.uid;
      await firebaseService.addMealToPlan(
        userId,
        newMeal.mealId,
        newMeal.name,
        newMeal.day,
        newMeal.time,
        {
          calories: newMeal.nutrition?.Calories || "N/A",
          fibres: newMeal.nutrition?.Fibres || "N/A",
          glucides: newMeal.nutrition?.Glucides || "N/A",
          matieresGrasses: newMeal.nutrition?.["Matières grasses"] || "N/A",
          proteines: newMeal.nutrition?.Protéines || "N/A",
        }
      );

      // Update local state to reflect the new meal
      this.setState((prevState) => ({
        mealPlan: [...prevState.mealPlan, newMeal],
      }));
    } catch (error) {
      console.error("Error adding meal:", error);
    }
  };

  // Handle meal deletion
  deleteMeal = async (planId) => {
    try {
      const userId = auth.currentUser.uid;

      // Remove meal from plan for the user and paired users using the unique plan entry key
      await firebaseService.removeMealFromPlan(userId, planId);

      // Update state for the current user
      this.setState((prevState) => ({
        mealPlan: prevState.mealPlan.filter((meal) => meal.id !== planId),
      }));
    } catch (error) {
      console.error("Error deleting meal:", error);
    }
  };

  // Check if the add meal button should be disabled (if all 3 meal times are added)
  isAddMealDisabled = (day) => {
    const mealsForDay = this.getMealsForDay(day);
    const mealTimes = mealsForDay.map((meal) => meal.time);
    return (
      mealTimes.includes("Breakfast") &&
      mealTimes.includes("Lunch") &&
      mealTimes.includes("Dinner")
    );
  };

  getMealsForDay = (day) => {
    const mealsForDay = this.state.mealPlan.filter((meal) => meal.day === day);
    return mealsForDay.sort((a, b) => {
      const order = { Breakfast: 1, Lunch: 2, Dinner: 3 };
      return order[a.time] - order[b.time];
    });
  };

  parseIngredientString = (ingredientString) => {
    // Step 1: Normalize the string
    const normalizedString = ingredientString.replace(/\s*\/\s*/g, "/").trim();

    console.log(`Normalized String: ${normalizedString}`);

    // Step 2: Extract the quantity (fractions, decimals, or integers)
    const quantityRegex = /^(\d+\s*\d*\/\d+|\d+\/\d+|\d+(\.\d+)?)/; // Match mixed numbers, fractions, decimals, or integers
    const quantityMatch = normalizedString.match(quantityRegex);
    let quantity = 1; // Default quantity

    console.log("Quantity Match:", quantityMatch);

    if (quantityMatch) {
      quantity = quantityMatch[0];
      if (quantity.includes(" ")) {
        const [whole, fraction] = quantity.split(" ");
        const [numerator, denominator] = fraction.split("/").map(Number);
        quantity = parseFloat(whole) + numerator / denominator;
      } else if (quantity.includes("/")) {
        const [numerator, denominator] = quantity.split("/").map(Number);
        quantity = numerator / denominator;
      } else {
        quantity = parseFloat(quantity);
      }
    }

    console.log(`Extracted Quantity: ${quantity}`);

    // Step 3: Remove the extracted quantity from the string
    const remainingAfterQuantity = normalizedString
      .replace(quantityRegex, "")
      .trim();

    // Step 4: Extract the unit (only common units or abbreviations)
    const unitRegex =
      /^\b(?:ml|g|kg|l|tsp|tbsp|cup|bou\.|oz|lb|cl|pcs?|bottle|can|pack|slice|pinch|dash|handful)\b/i;
    const unitMatch = remainingAfterQuantity.match(unitRegex);
    const unit = unitMatch ? unitMatch[0].toLowerCase() : null;

    console.log(`Extracted Unit: ${unit}`);

    // Step 5: Remove the extracted unit from the string
    const ingredientName = unit
      ? remainingAfterQuantity.replace(unitRegex, "").trim()
      : remainingAfterQuantity.trim();

    console.log(`Ingredient Name: ${ingredientName}`);

    // Step 6: Return the parsed result
    return {
      quantity: isNaN(quantity) ? 1 : quantity,
      unit: unit || null,
      name: ingredientName || "Unknown Ingredient",
    };
  };

  generateGroceryList = async () => {
    try {
      const userId = auth.currentUser.uid;
      const { mealPlan } = this.state;

      let ingredientMap = {};
      const quantityMultiplier = 2; // Scale for 2 people

      for (let meal of mealPlan) {
        const mealDetails = await firebaseService.getMealById(meal.mealId);
        if (mealDetails && mealDetails.ingredients) {
          for (let ingredient of mealDetails.ingredients) {
            console.log(mealDetails.ingredients);
            const parsedIngredient = this.parseIngredientString(ingredient);
            console.log(parsedIngredient);

            if (
              !parsedIngredient.name ||
              parsedIngredient.name === "Unknown Ingredient"
            ) {
              console.warn("Skipping invalid ingredient:", ingredient);
              continue;
            }

            const key = `${parsedIngredient.name.toLowerCase()}-${
              parsedIngredient.unit || ""
            }`;

            // Store associated meal names for each ingredient
            const mealName = mealDetails.name;

            if (ingredientMap[key]) {
              ingredientMap[key].quantity +=
                (parsedIngredient.quantity || 0) * quantityMultiplier;
              // Append meal name to the list if not already added
              if (!ingredientMap[key].meals.includes(mealName)) {
                ingredientMap[key].meals.push(mealName);
              }
            } else {
              ingredientMap[key] = {
                name: parsedIngredient.name,
                unit: parsedIngredient.unit,
                quantity: (parsedIngredient.quantity || 0) * quantityMultiplier,
                meals: [mealName], // Store associated meal names
              };
            }
          }
        }
      }

      // Generate a simplified list for display, excluding meal names
      const consolidatedIngredients = Object.values(ingredientMap).map(
        (item) => {
          const quantity = item.quantity
            ? Number.isInteger(item.quantity)
              ? item.quantity // Keep integers as they are
              : parseFloat(item.quantity.toFixed(2)) // Format to two decimal places
            : "";

          const unit = item.unit ? ` ${item.unit}` : "";
          const name = ` ${item.name}`;

          // Fix potential trailing zeros (e.g., 1.20 => 1.2)
          const formattedQuantity =
            typeof quantity === "number"
              ? parseFloat(quantity.toString())
              : quantity;

          return `${formattedQuantity}${unit}${name}`.trim(); // Exclude meal names here
        }
      );

      console.log("Final Consolidated Grocery List:", consolidatedIngredients);

      // Save both the full ingredient map and display list to the backend
      await firebaseService.resetGroceryList(userId);

      for (const [key, ingredient] of Object.entries(ingredientMap)) {
        const formattedQuantity = ingredient.quantity
          ? Number.isInteger(ingredient.quantity)
            ? ingredient.quantity // Keep integers as they are
            : parseFloat(ingredient.quantity.toFixed(2)) // Format to two decimal places
          : "";

        await firebaseService.addItemToGroceryList(userId, {
          display: `${formattedQuantity || ""}${
            ingredient.unit ? ` ${ingredient.unit}` : ""
          } ${ingredient.name}`.trim(),
          details: {
            meals: ingredient.meals, // Keep associated meal names here
            quantity: formattedQuantity,
            unit: ingredient.unit,
            name: ingredient.name,
          },
        });
      }
    } catch (error) {
      console.error("Error generating grocery list:", error);
    }
  };

  getConsolidatedNutritionForDay = (day) => {
    const mealsForDay = this.getMealsForDay(day);
    console.log(
      "This is the list of the meals for the day from getConsolidatedNutritionForDay",
      mealsForDay
    );

    const totals = mealsForDay.reduce(
      (acc, meal) => {
        const mealNutrition = meal.nutrition || {};

        acc.calories += parseInt(mealNutrition.calories) || 0;
        acc.fibres += parseFloat(mealNutrition.fibres) || 0;
        acc.glucides += parseFloat(mealNutrition.glucides) || 0;
        acc.matieresGrasses += parseFloat(mealNutrition.matieresGrasses) || 0;
        acc.proteines += parseFloat(mealNutrition.proteines) || 0;

        return acc;
      },
      { calories: 0, fibres: 0, glucides: 0, matieresGrasses: 0, proteines: 0 }
    );

    return totals;
  };

  renderMealPlan = () => {
    return (
      <div className="meal-plan-container">
        {daysOfWeek.map((day, index) => {
          const nutrition = this.getConsolidatedNutritionForDay(
            this.getFullDayName(index)
          );

          return (
            <div key={day}>
              <div className="day-div">
                <div className="day-label">
                  <span>{day}</span>
                  {/* Display consolidated nutrition data */}
                  <span className="nutrition-summary">
                    {` ${nutrition.calories} kcal, Fib: ${nutrition.fibres} g, Carb: ${nutrition.glucides} g, Fat: ${nutrition.matieresGrasses} g, Prot: ${nutrition.proteines} g`}
                  </span>
                </div>
                {/* Add meal button */}
                <button
                  className="add-meal-button"
                  onClick={() =>
                    this.handleOpenAddDirectMealModal(
                      this.getFullDayName(index)
                    )
                  }
                  disabled={this.isAddMealDisabled(this.getFullDayName(index))}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
              <div className="meal-plan-row">
                <div className="meal-plan-chips">
                  {this.getMealsForDay(this.getFullDayName(index)).map(
                    (meal) => (
                      <Link
                        to={`/meal/${meal.mealId}`}
                        key={meal.id}
                        className="meal-chip-link"
                        state={{
                          from: "/meal-plan",
                          scrollPosition: window.scrollY,
                        }}
                      >
                        <div
                          className={`meal-chip ${meal.time.toLowerCase()}-chip`}
                        >
                          <FontAwesomeIcon
                            icon={mealIcons[meal.time]}
                            className="meal-icon"
                          />
                          <span className="meal-name">{meal.name}</span>
                          {/* Delete button */}
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="delete-icon"
                            onClick={(e) => {
                              e.preventDefault(); // Prevent navigation on delete
                              this.deleteMeal(meal.id);
                            }}
                          />
                        </div>
                      </Link>
                    )
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  getFullDayName = (index) => {
    const fullDayNames = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    return fullDayNames[index];
  };

  render() {
    return (
      <div className="meal-plan-page">
        <TopBar title="Weekly Meal Plan" />
        {this.renderMealPlan()}

        <AddDirectMealModal
          open={this.state.showAddDirectMealModal}
          onClose={this.handleCloseAddDirectMealModal}
          onAddMeal={this.handleAddDirectMeal}
          day={this.state.selectedDay}
        />

        {/* Generate Grocery List Button */}
        <div className="generate-grocery-list-container">
          <Link to="/grocery-list">
            <button
              className="generate-grocery-list-button"
              onClick={this.generateGroceryList}
            >
              Generate Grocery List
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

export default MealPlanPage;
