import { db, auth } from "./firebaseConfig";
import {
  ref,
  push,
  set,
  get,
  query,
  orderByKey,
  limitToFirst,
  update,
  remove,
  startAfter,
  equalTo,
  orderByChild,
  getDatabase,
} from "firebase/database";
import { onAuthStateChanged } from "firebase/auth";

const prioritizeMeals = (meals, userPreferences, pairedUserLikedMeals) => {
  const calculateScore = (meal) => {
    let score = 0;

    // 1. Score for meals liked by the paired user
    if (pairedUserLikedMeals.has(meal.id)) {
      score += 10; // Prioritize meals liked by paired user
    }

    // 2. Score based on category preferences
    if (userPreferences.categories.includes(meal.category)) {
      score += 5; // Increment score if meal category matches user preference
    }

    // 3. Score based on Nutri-score preference
    const mealNutriScore = meal.scores?.["Nutri-score"]?.split(" ")[1]; // Extract "A", "B", etc.
    const minNutriScore = userPreferences.nutriScores; // Minimum Nutri-score (e.g., "C")
    if (mealNutriScore && mealNutriScore >= minNutriScore) {
      score += 3; // Increment score if Nutri-score meets or exceeds user’s minimum requirement
    }

    // 4. Score based on Eco-score preference
    const mealEcoScore = meal.scores?.["Eco-score"]?.split(" ")[1]; // Extract "A", "B", etc.
    const minEcoScore = userPreferences.ecoScores; // Minimum Eco-score (e.g., "C")
    if (mealEcoScore && mealEcoScore >= minEcoScore) {
      score += 3; // Increment score if Eco-score meets or exceeds user’s minimum requirement
    }

    return score;
  };

  return meals
    .map((meal) => ({ ...meal, score: calculateScore(meal) }))
    .sort((a, b) => b.score - a.score);
};

const getPairedUserIds = async (userId) => {
  try {
    const userRef = ref(db, `users/${userId}/pairedUserIds`);
    const snapshot = await get(userRef);

    if (snapshot.exists()) {
      return Object.values(snapshot.val()); // Return array of paired user IDs
    }
    return [];
  } catch (error) {
    console.error("Error fetching paired user IDs:", error);
    return [];
  }
};

const getMeals = async (userId, setMeals) => {
  try {
    const mealsRef = ref(db, "meals");
    const mealsSnapshot = await get(mealsRef);
    if (!mealsSnapshot.exists()) {
      console.log("No more meals available");
      return null;
    }

    const mealsData = mealsSnapshot.val();
    const allMeals = Object.keys(mealsData).map((key) => ({
      id: key,
      ...mealsData[key],
    }));

    // Fetch swiped meals to filter out already swiped meals
    const swipesRef = ref(db, `swipes/${userId}`);
    const swipesSnapshot = await get(swipesRef);

    let filteredMeals = allMeals;
    if (swipesSnapshot.exists()) {
      const swipesData = swipesSnapshot.val();
      const swipedMealIds = new Set(
        Object.values(swipesData).map((swipe) => swipe.mealId)
      );
      filteredMeals = allMeals.filter((meal) => !swipedMealIds.has(meal.id));
    }

    // Fetch user preferences and paired user liked meals for scoring
    const preferences = await getDietaryPreferences(userId);
    console.log("This is what preferences look like", preferences);
    const pairedUserIds = await getPairedUserIds(userId);
    const pairedUserLikedMeals = await getPairedUserLikedMeals(pairedUserIds);

    // Prioritize meals based on preferences and paired user likes
    filteredMeals = prioritizeMeals(
      filteredMeals,
      preferences,
      pairedUserLikedMeals
    );

    // No shuffling here to keep the prioritized order
    if (filteredMeals.length > 0) {
      setMeals((prevMeals) => [...prevMeals, ...filteredMeals]);
      return filteredMeals[filteredMeals.length - 1].id;
    }
    return null;
  } catch (error) {
    console.error("Error fetching meals:", error);
    return null;
  }
};

const getMatches = async (setSuperMatches, setRegularMatches, userId) => {
  try {
    const matchesRef = ref(db, "matches");
    const snapshot = await get(matchesRef);

    if (!snapshot.exists()) {
      setSuperMatches([]);
      setRegularMatches([]);
      return;
    }

    const matchesData = snapshot.val();
    const userMatches = Object.keys(matchesData)
      .map((key) => ({
        id: key,
        ...matchesData[key],
      }))
      .filter((match) => match.users.includes(userId));

    const superMatches = [];
    const regularMatches = [];

    userMatches.forEach((match) => {
      if (match.isSuperMatch) {
        superMatches.push(match);
      } else {
        regularMatches.push(match);
      }
    });

    if (typeof setSuperMatches === "function") {
      setSuperMatches(superMatches);
    } else {
      console.error("setSuperMatches is not a function");
    }

    if (typeof setRegularMatches === "function") {
      setRegularMatches(regularMatches);
    } else {
      console.error("setRegularMatches is not a function");
    }
  } catch (error) {
    console.error("Error fetching matches:", error);
    if (typeof setSuperMatches === "function") {
      setSuperMatches([]);
    }
    if (typeof setRegularMatches === "function") {
      setRegularMatches([]);
    }
  }
};

const getUserMatches = async (userId) => {
  try {
    const matchesRef = ref(db, "matches");
    const snapshot = await get(matchesRef);

    if (!snapshot.exists()) {
      return [];
    }

    const matchesData = snapshot.val();
    const userMatches = Object.keys(matchesData)
      .map((key) => ({
        id: key,
        ...matchesData[key],
      }))
      .filter((match) => match.users.includes(userId));

    return userMatches;
  } catch (error) {
    console.error("Error fetching matches:", error);
    return [];
  }
};

// Function to record a rating for a meal
const recordRating = async (userId, mealId, ratingType) => {
  const swipeRef = ref(db, `swipes/${userId}`);
  const newSwipeRef = push(swipeRef);
  await set(newSwipeRef, { mealId, ratingType, isMatched: false });

  if (ratingType === "Dislike") return null; // Exit if user dislikes the meal

  // Retrieve user data to get paired user IDs
  const userRef = ref(db, `users/${userId}`);
  const userSnapshot = await get(userRef);
  if (!userSnapshot.exists()) return null;

  const userData = userSnapshot.val();
  const pairedUserIds = userData.pairedUserIds || [];

  let match = null;

  // Iterate through each paired user ID to check for potential matches
  for (const pairedUserId of pairedUserIds) {
    const pairedUserSwipesRef = ref(db, `swipes/${pairedUserId}`);
    const pairedUserSwipesSnapshot = await get(pairedUserSwipesRef);

    if (pairedUserSwipesSnapshot.exists()) {
      const pairedUserSwipes = pairedUserSwipesSnapshot.val();

      // Check each swipe of the paired user for a match
      for (const swipeKey in pairedUserSwipes) {
        const swipe = pairedUserSwipes[swipeKey];
        if (
          swipe.mealId === mealId &&
          swipe.ratingType !== "Dislike" &&
          ["Why not", "Like", "Best"].includes(swipe.ratingType)
        ) {
          // Determine if it's a supermatch
          const isSuperMatch = swipe.ratingType === ratingType;

          // Fetch meal details for the match
          const mealRef = ref(db, `meals/${mealId}`);
          const mealSnapshot = await get(mealRef);
          if (!mealSnapshot.exists()) continue;
          const mealData = mealSnapshot.val();

          // Create a match entry
          const matchesRef = ref(db, "matches");
          const newMatchRef = push(matchesRef);
          await set(newMatchRef, {
            mealId: mealId,
            users: [userId, pairedUserId],
            name: mealData.title,
            imageUrl: mealData.img_link,
            timestamp: Date.now(),
            ratings: {
              [userId]: ratingType,
              [pairedUserId]: swipe.ratingType,
            },
            isSuperMatch: isSuperMatch,
          });

          // Update the swipes to indicate they resulted in a match
          await update(ref(db, `swipes/${userId}/${newSwipeRef.key}`), {
            isMatched: true,
          });
          await update(ref(db, `swipes/${pairedUserId}/${swipeKey}`), {
            isMatched: true,
          });

          match = {
            id: newMatchRef.key,
            mealId: mealId,
            isSuperMatch: isSuperMatch,
          };
          break;
        }
      }
    }
    if (match) break; // Exit loop if a match is found
  }

  return match;
};

// Function to change the rating type for a meal
const changeRatingType = async (userId, mealId, newRatingType) => {
  try {
    const swipeRef = ref(db, `swipes/${userId}`);
    const swipesQuery = query(
      swipeRef,
      orderByChild("mealId"),
      equalTo(mealId)
    );
    const swipesSnapshot = await get(swipesQuery);

    if (swipesSnapshot.exists()) {
      const swipeKey = Object.keys(swipesSnapshot.val())[0];
      await update(ref(db, `swipes/${userId}/${swipeKey}`), {
        ratingType: newRatingType,
      });

      // Update existing matches
      const matchesRef = ref(db, "matches");
      const matchesQuery = query(
        matchesRef,
        orderByChild("mealId"),
        equalTo(mealId)
      );
      const matchesSnapshot = await get(matchesQuery);

      if (matchesSnapshot.exists()) {
        matchesSnapshot.forEach(async (matchSnapshot) => {
          const match = matchSnapshot.val();
          if (match.users.includes(userId)) {
            const otherUserId = match.users.find((id) => id !== userId);
            const otherUserRating = match.ratings[otherUserId];
            const isSuperMatch = otherUserRating === newRatingType;

            await update(ref(db, `matches/${matchSnapshot.key}`), {
              [`ratings.${userId}`]: newRatingType,
              isSuperMatch: isSuperMatch,
            });
          }
        });
      }
    } else {
      console.log(`No swipe data found for meal ${mealId}`);
    }
  } catch (error) {
    console.error("Error changing rating type:", error);
  }
};

const getLikedMeals = async (userId, setLikedMeals) => {
  try {
    const swipesRef = ref(db, `swipes/${userId}`);
    const snapshot = await get(swipesRef);

    const likedMeals = [];
    if (snapshot.exists()) {
      const swipesData = snapshot.val();
      for (const swipeKey in swipesData) {
        const swipe = swipesData[swipeKey];
        // Exclude matched swipes
        if (
          swipe.ratingType &&
          swipe.ratingType !== "Dislike" &&
          !swipe.isMatched
        ) {
          const mealRef = ref(db, `meals/${swipe.mealId}`);
          const mealSnapshot = await get(mealRef);
          if (mealSnapshot.exists()) {
            const mealData = mealSnapshot.val();
            likedMeals.push({
              mealId: swipe.mealId,
              swipeId: swipeKey,
              title: mealData.title,
              category: mealData.category,
              img_link: mealData.img_link,
              ratingType: swipe.ratingType,
            });
          }
        }
      }
    }
    setLikedMeals(likedMeals);
  } catch (error) {
    console.error("Error fetching liked meals:", error);
  }
};

const getPairedUserLikedMeals = async (pairedUserIds) => {
  const likedMeals = new Set(); // Use a Set to avoid duplicates
  try {
    for (const userId of pairedUserIds) {
      const swipesRef = ref(db, `swipes/${userId}`);
      const snapshot = await get(swipesRef);

      if (snapshot.exists()) {
        const swipesData = snapshot.val();
        for (const swipeKey in swipesData) {
          const swipe = swipesData[swipeKey];
          if (
            swipe.ratingType &&
            swipe.ratingType !== "Dislike" &&
            !swipe.isMatched
          ) {
            likedMeals.add(swipe.mealId); // Add meal ID to the Set
          }
        }
      }
    }
  } catch (error) {
    console.error("Error fetching liked meals for paired users:", error);
  }
  return likedMeals; // Return a Set of liked meal IDs for efficient lookup
};

// Function to unlike a meal
const unlikeMeal = async (userId, mealId) => {
  try {
    // Update swipes to set the ratingType to "Dislike"
    const swipesRef = ref(db, `swipes/${userId}`);
    const swipesQuery = query(
      swipesRef,
      orderByChild("mealId"),
      equalTo(mealId)
    );
    const swipesSnapshot = await get(swipesQuery);

    if (swipesSnapshot.exists()) {
      const swipeId = Object.keys(swipesSnapshot.val())[0];
      await update(ref(db, `swipes/${userId}/${swipeId}`), {
        ratingType: "Dislike",
      });
    } else {
      console.log("No swipe data available to update");
    }

    // Remove from matches
    const matchesRef = ref(db, "matches");
    const matchesQuery = query(
      matchesRef,
      orderByChild("mealId"),
      equalTo(mealId)
    );
    const matchesSnapshot = await get(matchesQuery);

    if (matchesSnapshot.exists()) {
      matchesSnapshot.forEach(async (matchSnapshot) => {
        const match = matchSnapshot.val();
        if (match.users.includes(userId)) {
          // Remove for both users
          await remove(ref(db, `matches/${matchSnapshot.key}`));
          const otherUserId = match.users.find((id) => id !== userId);
          const otherUserSwipesRef = ref(db, `swipes/${otherUserId}`);
          const otherUserSwipesQuery = query(
            otherUserSwipesRef,
            orderByChild("mealId"),
            equalTo(mealId)
          );
          const otherUserSwipesSnapshot = await get(otherUserSwipesQuery);

          if (otherUserSwipesSnapshot.exists()) {
            const otherUserSwipeId = Object.keys(
              otherUserSwipesSnapshot.val()
            )[0];
            await update(ref(db, `swipes/${otherUserId}/${otherUserSwipeId}`), {
              ratingType: "Dislike",
            });
          }
        }
      });
    } else {
      console.log("No match data available to remove");
    }
    return true;
  } catch (error) {
    console.error("Error unliking meal: ", error);
    return false;
  }
};

const getMealById = async (mealId) => {
  try {
    const mealRef = ref(db, `meals/${mealId}`);
    const mealSnapshot = await get(mealRef);
    if (mealSnapshot.exists()) {
      const mealData = mealSnapshot.val();
      return { id: mealId, ...mealData };
    } else {
      console.log("No meal data found for ID by getMealById:", mealId);
      return null;
    }
  } catch (error) {
    console.error("Error fetching meal by ID:", error);
    return null;
  }
};

// in firebaseService.js or a similar service file
const getMatchesWithMealDetails = async () => {
  const matchesRef = ref(db, "matches");
  const snapshot = await get(matchesRef);
  if (!snapshot.exists()) {
    console.log("No matches available");
    return [];
  }

  const matchesData = snapshot.val();
  const matchesWithDetails = await Promise.all(
    Object.keys(matchesData).map(async (key) => {
      const match = matchesData[key];
      const mealRef = ref(db, `meals/${match.mealId}`);
      const mealSnapshot = await get(mealRef);
      if (mealSnapshot.exists()) {
        const mealData = mealSnapshot.val();
        return {
          ...match,
          mealDetails: mealData,
        };
      } else {
        console.error(`No meal data found for ID: ${match.mealId}`);
        return match; // return match without meal details if not found
      }
    })
  );

  return matchesWithDetails;
};

const getMealPlan = async (userId) => {
  try {
    const mealPlanRef = ref(db, `mealPlans/${userId}`);
    const snapshot = await get(mealPlanRef);
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      return []; // No meal plan exists yet
    }
  } catch (error) {
    console.error("Error fetching meal plan: ", error);
    return [];
  }
};

const removeMealFromPlan = async (userId, planId) => {
  try {
    // Fetch the meal plan entry to get the sharedId
    const mealPlanRef = ref(db, `mealPlans/${userId}/${planId}`);
    const mealPlanSnapshot = await get(mealPlanRef);

    if (!mealPlanSnapshot.exists()) {
      console.error("Meal plan entry not found for user", userId);
      return;
    }

    const { sharedId } = mealPlanSnapshot.val();

    // Remove meal plan entry for the current user
    await remove(mealPlanRef);

    // Fetch paired user IDs from the current user's data
    const userRef = ref(db, `users/${userId}`);
    const userSnapshot = await get(userRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.val();
      const pairedUserIds = userData.pairedUserIds || [];

      // Remove the meal for each paired user based on the sharedId
      for (const pairedUserId of pairedUserIds) {
        const pairedMealPlanRef = ref(db, `mealPlans/${pairedUserId}`);
        const pairedMealPlanSnapshot = await get(pairedMealPlanRef);

        if (pairedMealPlanSnapshot.exists()) {
          const pairedMealPlanData = pairedMealPlanSnapshot.val();

          // Find the entry with the matching sharedId and remove it
          for (const key in pairedMealPlanData) {
            if (pairedMealPlanData[key].sharedId === sharedId) {
              const pairedUserMealRef = ref(
                db,
                `mealPlans/${pairedUserId}/${key}`
              );
              await remove(pairedUserMealRef);
            }
          }
        }
      }
    }
  } catch (error) {
    console.error("Error removing meal from plan:", error);
  }
};

// Function to get dietary preferences (already defined above)

const getDietaryPreferences = async (userId) => {
  try {
    const userRef = ref(db, `users/${userId}/preferences`);
    const snapshot = await get(userRef);

    if (snapshot.exists()) {
      const data = snapshot.val();

      return {
        categories: data.categories || [], // Default to empty array if not set
        nutriScores: data.nutriScores || "A", // Directly return letter, default to "A"
        ecoScores: data.ecoScores || "A", // Directly return letter, default to "A"
      };
    } else {
      // Return default values if preferences do not exist
      return {
        categories: [],
        nutriScores: "A", // Default to "A"
        ecoScores: "A", // Default to "A"
      };
    }
  } catch (error) {
    console.error("Error fetching dietary preferences: ", error);
    return {
      categories: [],
      nutriScores: "A", // Default to "A"
      ecoScores: "A", // Default to "A"
    };
  }
};

const saveDietaryPreferences = async (userId, preferences) => {
  try {
    const userRef = ref(db, `users/${userId}/preferences`);

    // Construct preferences object directly from preferencesToSave
    const preferencesToSave = {
      categories: preferences.categories || [], // Ensure categories is an array
      nutriScores: preferences.nutriScores, // Already a letter (e.g., "A", "B", etc.)
      ecoScores: preferences.ecoScores, // Already a letter (e.g., "A", "B", etc.)
    };

    // Use set to ensure the preferences node is created if it doesn't exist
    await set(userRef, preferencesToSave);
    return true;
  } catch (error) {
    console.error("Error saving dietary preferences: ", error);
    return false;
  }
};

const addMealToPlan = async (userId, mealId, name, day, time) => {
  try {
    // Generate a shared ID to link the meal plan entry between paired users
    const sharedId = push(ref(db)).key;

    // Fetch the meal's full data, including nutrition, from the database
    const mealRef = ref(db, `meals/${mealId}`);
    const mealSnapshot = await get(mealRef);

    if (!mealSnapshot.exists()) {
      console.error(`Meal with ID ${mealId} not found in the database.`);
      return;
    }

    const mealData = mealSnapshot.val();

    // Extract nutrition data
    const nutrition = mealData.nutrition || {};

    // Define the meal entry with the shared ID, meal name, and nutrition data
    const newMeal = {
      mealId,
      name,
      day,
      time,
      sharedId,
      nutrition: {
        calories: nutrition.Calories || "N/A",
        fibres: nutrition.Fibres || "N/A",
        glucides: nutrition.Glucides || "N/A",
        matieresGrasses: nutrition["Matières grasses"] || "N/A",
        proteines: nutrition.Protéines || "N/A",
      },
    };

    console.log("In addMealToPlan, we're adding this", newMeal);

    // Add meal to the current user's meal plan
    const mealPlanRef = ref(db, `mealPlans/${userId}`);
    await push(mealPlanRef, newMeal);

    // Fetch paired user IDs from the user data
    const userRef = ref(db, `users/${userId}`);
    const userSnapshot = await get(userRef);
    if (userSnapshot.exists()) {
      const userData = userSnapshot.val();
      const pairedUserIds = userData.pairedUserIds || [];

      // Add the meal to each paired user's meal plan with the same shared ID and nutrition
      for (const pairedUserId of pairedUserIds) {
        const pairedMealPlanRef = ref(db, `mealPlans/${pairedUserId}`);
        await push(pairedMealPlanRef, newMeal);
      }
    }
  } catch (error) {
    console.error("Error adding meal to plan:", error);
  }
};

const addItemToGroceryList = async (userId, ingredient) => {
  console.log(ingredient);
  try {
    // Validate ingredient data
    if (!ingredient || typeof ingredient !== "object") {
      throw new Error(
        "Invalid ingredient data: Ingredient must be an object with display and details properties"
      );
    }

    const { display, details } = ingredient;

    if (
      !display ||
      typeof display !== "string" ||
      display.trim() === "" ||
      !details ||
      typeof details !== "object"
    ) {
      throw new Error(
        "Invalid ingredient data: Both display (string) and details (object) are required"
      );
    }

    // Generate a shared ID to link the grocery list entry between paired users
    const sharedId = push(ref(db)).key;

    // Prepare the new item with the shared ID
    const newItem = {
      display, // Simplified ingredient string for UI display
      details, // Full ingredient details including meals and metadata
      sharedId,
    };

    // Fetch the current user's paired user IDs
    const userRef = ref(db, `users/${userId}`);
    const userSnapshot = await get(userRef);
    if (!userSnapshot.exists()) {
      throw new Error("User not found");
    }

    const userData = userSnapshot.val();
    const pairedUserIds = userData.pairedUserIds || [];

    // List of user IDs to update (includes the current user and paired users)
    const userIdsToUpdate = [userId, ...pairedUserIds];

    // Loop through each user and update their grocery list
    for (const id of userIdsToUpdate) {
      const groceryListRef = ref(db, `groceryLists/${id}`);
      const groceryListSnapshot = await get(groceryListRef);

      let itemExists = false;

      if (groceryListSnapshot.exists()) {
        const groceryListData = groceryListSnapshot.val();

        for (const key in groceryListData) {
          const existingItem = groceryListData[key];

          // Check if the existing item matches by display name (case-insensitive)
          if (
            existingItem.display &&
            existingItem.display.toLowerCase() === display.toLowerCase()
          ) {
            itemExists = true;
            break;
          }
        }
      }

      // If the item does not exist in the list, add it as a new entry
      if (!itemExists) {
        await push(groceryListRef, newItem);
      }
    }
  } catch (error) {
    console.error("Error adding item to grocery list:", error);
  }
};

const getGroceryList = async (userId) => {
  try {
    const groceryListRef = ref(db, `groceryLists/${userId}`);
    const snapshot = await get(groceryListRef);

    if (!snapshot.exists()) {
      console.log("No items available in the grocery list for user:", userId);
      return [];
    }

    const groceryData = snapshot.val();

    // Map the grocery data to return the display name and full details
    return Object.keys(groceryData).map((key) => {
      const item = groceryData[key];
      return {
        id: key, // Firebase-generated key for each grocery item
        display: item.display || "Unknown Item", // Simplified display name
        details: item.details || null, // Full details object (optional, null if missing)
      };
    });
  } catch (error) {
    console.error("Error fetching grocery list for user:", userId, error);
    return [];
  }
};

const removeItemFromGroceryList = async (userId, sharedId) => {
  try {
    // Fetch paired user IDs from the user data
    const userRef = ref(db, `users/${userId}`);
    const userSnapshot = await get(userRef);

    if (!userSnapshot.exists()) {
      throw new Error("User not found");
    }

    const userData = userSnapshot.val();
    const pairedUserIds = userData.pairedUserIds || [];

    // List of users to update (the current user and paired users)
    const userIdsToUpdate = [userId, ...pairedUserIds];

    // Remove the item from each user's grocery list based on the sharedId
    for (const id of userIdsToUpdate) {
      const groceryListRef = ref(db, `groceryLists/${id}`);
      const groceryListSnapshot = await get(groceryListRef);

      if (groceryListSnapshot.exists()) {
        const groceryData = groceryListSnapshot.val();

        // Find and remove the item with the matching sharedId
        for (const key in groceryData) {
          if (groceryData[key].sharedId === sharedId) {
            const itemRef = ref(db, `groceryLists/${id}/${key}`);
            await remove(itemRef);
          }
        }
      }
    }
  } catch (error) {
    console.error("Error removing item from grocery list:", error);
  }
};

const resetGroceryList = async (userId) => {
  try {
    // Fetch paired user IDs from the user data
    const userRef = ref(db, `users/${userId}`);
    const userSnapshot = await get(userRef);

    if (!userSnapshot.exists()) {
      throw new Error("User not found");
    }

    const userData = userSnapshot.val();
    const pairedUserIds = userData.pairedUserIds || [];

    // List of users to update (the current user and paired users)
    const userIdsToUpdate = [userId, ...pairedUserIds];

    // Remove all items from each user's grocery list
    for (const id of userIdsToUpdate) {
      const groceryListRef = ref(db, `groceryLists/${id}`);
      await remove(groceryListRef);
    }
  } catch (error) {
    console.error("Error resetting grocery list:", error);
  }
};

export const firebaseService = {
  getMeals,
  getMealById,
  getMatches,
  getUserMatches,
  recordRating,
  getLikedMeals,
  unlikeMeal,
  changeRatingType,
  getDietaryPreferences,
  saveDietaryPreferences,
  getMatchesWithMealDetails,
  getMealPlan,
  addMealToPlan,
  removeMealFromPlan,
  addItemToGroceryList,
  getGroceryList,
  removeItemFromGroceryList,
  resetGroceryList,
};
